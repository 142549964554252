import React from "react";
import { useParams } from "react-router-dom";
// import forge from "../../assets/images/forge-the-drill.web.app.png";
import ownSell from "../../assets/images/ownSell.png";
import gloryShop from "../../assets/images/gloryShop.png";
import corporateZone from "../../assets/images/corporateZone.png";
import { useEffect } from "react";
import { useState } from "react";
import "./PorjectDetails.css";
import ScreenShotModal from "./ScreenShotModal";

const ProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [openedImage, setOpenedImage] = useState("");

  const projects = [
    {
      id: 1,
      title: "Own Sell",
      name: "Multi vendors Service-based platform",
      description: [
        "Secure Authentication, KYC Verification for vendor Multilevel Search Functionality Payment System, Customer history, review, Admin can do vendor account Active or Inactive.",
        "Vendor Dashboard manages all sales, earnings, orders, and customer, update order status, Add new service explore sales report.",
        "Admin Dashboard Visually observe with grapes platform all activities portal earning, sales, total vendor, vendor all statices, Admin can add Category, Subcategory filter Platform total report monthly weekly or daily and download report pdf format.",
        "Three types of dashboard access test mails: admin@gmail.com, vendor@gmail.com, customer@gmail.com, password: 123456 for all role.",
      ],
      image: ownSell,
      links: {
        live: "https://own-sell.web.app",
        client: "https://github.com/Nesat-Nayem/own-shop",
        server: "https://github.com/Nesat-Nayem/own-shop-backend",
      },
      technology: [
        "Html",
        "Css",
        "JavaScript",
        "React",
        "Redux",
        "JWT",
        "Reacharts",
        "Material UI",
        "Node.js",
        "Heroku",
        "ExpresJs",
        "Mongoose,",
        "MongoDB",
        "Stripe",
      ],
      screenshots: [
        "https://i.postimg.cc/BvgxyMSG/won1.png",
        "https://i.postimg.cc/yYMXSDQ2/own2.png",
        "https://i.postimg.cc/ZKCVNtTv/won3.png",
        "https://i.postimg.cc/nLZ406tk/own4.png",
        "https://i.postimg.cc/0QkC2hWQ/own5.png",
        "https://i.postimg.cc/5tq3JfmT/own6.png",
      ],
    },
    {
      id: 2,
      title: "Glory Shop",
      name: "E-Commerce Platform",
      description: [
        "User Can add food to their wishlist for buying future.",
        "Users can update product quantity on their cart.",
        "User can see her order and can pay via stripe and google authentication.",
      ],
      image: gloryShop,
      links: {
        live: "https://glory-shop.web.app/",
        client: "https://github.com/Nesat-Nayem/glory-shop-client",
        server: "https://github.com/Nesat-Nayem/glory-shop-backend",
      },
      technology: [
        "React.js",
        "React-Router(v6)",
        "Redux",
        "Redux-Toolkit",
        "Bootstrap",
        "Swipe Js",
        "Firebase",
        "Heroku",
        "Node.js",
        "Express.js",
        "MongoDB",
      ],
      screenshots: [
        "https://i.postimg.cc/FzgY86Zb/shop1.png",
        "https://i.postimg.cc/rm40CsRP/shop2.png",
        "https://i.postimg.cc/dQ8Zh7Y7/shop3.png",
        "https://i.postimg.cc/KjyK9bnv/shop5.png",
      ],
    },
    {
      id: 3,
      title: "Corporate Zone",
      name: "Job Portal & Team Project",
      description: [
        "A recruiter can post a job, A candidate can apply for this job and the Admin can manage everything like when a recruiter posts a job it is fast pending and if the admin approves it then it shows UI.",
        "Real-time text Chat with the candidate and recruiter we build it sockit.io and video chat we integrate video chat our project with Jitsi.",
        "Notification when the recruiter posts a job then notifies all candidates .",
        "Also, a lot of features are available like skill tests, building a resume, stripe payment, and more.",
      ],
      image: corporateZone,
      links: {
        live: "https://corporate-zone.vercel.app/",
        client: "https://github.com/Nesat-Nayem/CorporateZone-Client",
        server: "https://github.com/Nesat-Nayem/CorporateZone-Backend",
      },
      technology: [
        "React.js",
        "Next.js",
        "Stripe",
        "React-Redux",
        "Redux-Toolkit",
        "Socket.IO",
        "Tailwind CSS",
        "Node.js",
        "ExpresJs",
        "Mongoose",
        "MongoDB",
      ],
      screenshots: [
        "https://i.postimg.cc/RCtWJWcr/corporat1.png",
        "https://i.postimg.cc/kGWG0HnM/corporate2.png",
        "https://i.postimg.cc/CLVzGwNR/corporate3.png",
        "https://i.postimg.cc/GhJ9z67q/corporate4.png",
      ],
    },
  ];

  useEffect(() => {
    const project = projects.find((x) => x.id === +id);
    setProject(project);
    console.log(project);
  }, [id]);

  return (
    <div
      data-aos="zoom-in-right"
      data-aos-duration="1500"
      className="detail-card flex flex-col xl:mt-14 lg:min-h-screen p-5 lg:p-40 hover:text-white"
    >
      <div className="inside-detail flex flex-col lg:flex-row justify-center items-start gap-8 lg:h-[60vh] text-left mb-32">
        <div className="image-detail w-full h-80  lg:w-1/2 lg:h-full rounded-xl overflow-hidden">
          <img src={project.image} alt="" />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col h-full justify-center">
          <div>
            <h2 className="font-semibold  text-sm xl:my-2 my-4 text-secondary">
              {project.title}
            </h2>
            <h2 className="project-title xl:text-base text-accent font-semibold xl:my-2 text-xl my-4">
              {project.name}
            </h2>
            <div className="my-5">
              <h3 className=" text-secondary xl:my-2 ">Project Overview</h3>
              {project?.description?.map((point, index) => (
                <p key={index} className="text-accent xl:text-sm ">
                  {index + 1}. {point}
                </p>
              ))}
            </div>
            <div className="my-5">
              <h3 className=" text-secondary xl:mb-3">Technology used</h3>
              <div className="flex flex-wrap xl:gap-3 gap-4">
                {project?.technology?.map((point, index) => (
                  <span key={index} className="text-accent xl:text-xs xl:py-1 xl:px-3 hover:none btn">
                    {point}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="button-content flex justify-between xl:mt-0 mt-5">
            <a
              href={project?.links?.live}
              target="_blank"
              rel="noreferrer"
              className="project-button text-[10px] lg:text-[12px]"
            >
              <i className="text-sm text-secondary mr-2 fa-solid fa-circle-dot"></i>
              View Live
            </a>
            <a
              href={project?.links?.client}
              target="_blank"
              rel="noreferrer"
              className="project-button text-[10px] lg:text-[12px]"
            >
              <i className="text-sm text-secondary mr-2 fa-brands fa-github"></i>
              Client Code
            </a>
            <a
              href={project?.links?.server}
              target="_blank"
              rel="noreferrer"
              className="project-button text-[10px] lg:text-[12px]"
            >
           
              <i className="text-sm text-secondary mr-2 fa-brands fa-github"></i>
              Server Code
            </a>
          </div>
        </div>
      </div>

      <div className="xl:mt-24">
        <p className="text-accent text-center xl:text-4xl font-bold text-xl lg:text-5xl mb-10">
          Project Screenshots
        </p>
        <div className="screenshot w-full grid grid-cols-1 lg:grid-cols-2 gap-10">
          {project?.screenshots?.map((screenshot) => (
            <button
              onClick={() => {
                setOpenedImage(screenshot);
                setIsOpen(true);
              }}
            >
              <img className="w-full rounded-3xl" src={screenshot} alt="" />
            </button>
          ))}
        </div>
      </div>
      {modalIsOpen && (
        <ScreenShotModal
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          openedImage={openedImage}
        />
      )}
    </div>
  );
};

export default ProjectDetails;

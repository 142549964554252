import React from "react";
import EducationCard from "./EducationCard";

const Trainee = () => {
  const trainee = [
    {
    degree: "Complete Web Development Course",
    institute: "Programming Hero",
    year: "June 2021 - June 2022",
  },
    {
    degree: "Web Design and Development",
    institute: "IT Bari",
    year: "January 2021 - May 2021",
  }
];
  return (
    <div>
      <div className="relative max-w-2xl mx-auto mt-16">
        <div className="absolute top-0 h-full border-r-2 border-black left-3"></div>
        <ul className="space-y-2">
          {
            trainee.map((trainee)=>(
              <EducationCard key={trainee.id} education={trainee} />
            ))
          }
         
        </ul>
      </div>
    </div>
  );
};

export default Trainee;

import React from "react";
import ProjectCard from "./ProjectCard";
// import forge from "../../assets/images/forge-the-drill.web.app.png";
import ownSell from "../../assets/images/ownSell.png";
// import bagsq from "../../assets/images/bagsq12.png";
import gloryShop from "../../assets/images/gloryShop.png";
// import blood from "../../assets/images/blood-buddies-donation.png";
import corporateZone from "../../assets/images/corporateZone.png";
import Modal from "react-modal";

Modal.setAppElement("#root");

const Portfolio = () => {
  const projects = [
    {
      id: 1,
      title: "Own Sell",
      name: "Multi vendors Service-based platform",
      // image: forge,
      image: ownSell,
      links: {
        live: "https://own-sell.web.app",
        client: "https://github.com/Nesat-Nayem/own-shop",
        server: "https://github.com/Nesat-Nayem/own-shop-backend",
      },
    },
    {
      id: 2,
      title: "GLORY SHOP",
      name: "Mordern E-Commerce Website For Selse Products",
      // image: bagsq,
      image: gloryShop,
      links: {
        live: "https://glory-shop.web.app/",
        client: "https://github.com/Nesat-Nayem/glory-shop-client",
        server: "https://github.com/Nesat-Nayem/glory-shop-backend",
      },
    },
    {
      id: 3,
      title: "Corporate Zone",
      name: "Job Portal For Recruiter And Candidate",
      // image: blood,
      image: corporateZone,
      links: {
        live: "https://corporate-zone.vercel.app/",
        client: "https://github.com/Nesat-Nayem/CorporateZone-Client",
        server: "https://github.com/Nesat-Nayem/CorporateZone-Backend",
      },
    },
  ];

  return (
    <div id="portfolio" className="text-white xl:max-w-6xl 2xl:max-w-7xl mx-auto">
      <div data-aos="zoom-in" data-aos-duration="500">
        <p className="text-xs lg:text-sm text-secondary uppercase">
         PORTFOLIO
        </p>
        <p className="text-accent font-bold text-3xl xl:text-4xl 2xl:text-5xl mb-10">
      Full Stack Projects
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
        {projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
